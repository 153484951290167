<template>
    <div class="c-cookie-banner">

        <transition
            appear
            name="fadein"
        >
            <div
                v-if="visible && !isTermsPage"
                class="c-cookie-banner__overlay"
            >
            </div>
        </transition>
        <div
            v-slide="visible"
            class="c-cookie-banner__wrapper"
        >
            <div class="c-cookie-banner__text">
                {{ trans('common.cookies.main') }}
                <span class="c-cookie-banner__link">
                    <a
                        :href="cookiePolicyUrl"
                        class="c-cookie-banner__link"
                        target="_blank"
                    >
                        {{ trans('common.cookies.cookie') }}.
                    </a>
                </span>
            </div>
            <div class="c-cookie-banner__btns">
                <button
                    class="c-cookie-banner__btn--secondary u-hover--grow"
                    :class="colorClass + '__border' && colorClass + '__text'"
                    @click="openPanel"
                >
                    {{ trans('common.cookies.manage') }}
                </button>
                <button
                    class="c-cookie-banner__btn--primary u-hover--grow"
                    :class="colorClass + '__bg'"
                    @click="acceptCookies"
                >
                    {{ trans('common.cookies.accept') }}
                </button>
            </div>
        </div>
        <cookie-panel
            v-if="panelVisible"
            v-slide="panelVisible"
            @closePanel="closePanel"
            @acceptCookies="acceptCookies"
        >
        </cookie-panel>
    </div>

</template>

<script>

import CookiePanel from './cookie-panel.vue';
import interactsWithCookieSettings from '../../vue-mixins/interactsWithCookieSettings.js';

export default {
    name: 'CookieBanner',
    components: {
        CookiePanel,
    },
    mixins: [
        interactsWithCookieSettings,
    ],
    props: {
        colorClass: {
            type: String,
            default: 'u-primary-color',
        },
    },
    data() {
        return {
            visible: !EzekiaLocalStorage.get('cookie-preferences'),
            baseUrl: nal.baseUrl,
        };
    },
    computed: {
        isTermsPage() {
            if (this.$route) {
                return this.$route.name === 'terms';
            }
            return location && location.href && location.href.includes('terms');
        },
        cookiePolicyUrl() {
            return '/terms/cookie-policy';
        },
    },
};

</script>

<style lang="scss" scoped>

@import "@sass/variables";
@import "@sass/mixins";

.c-cookie-banner {
    &__overlay {
        background: black;
        height: 100%;
        opacity: 0.5;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 98;
    }

    .fadein-enter-active {
        transition: opacity 0.85s;
    }

    .fadein-enter {
        opacity: 0;
    }

    &__wrapper {
        align-items: center;
        background-color: rgba(235, 235, 235, 0.95);
        bottom: 0;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        justify-content: center;
        left: 0;
        padding: 10px 12px;
        position: fixed;
        width: 100%;
        z-index: 98;
    }

    &__text {
        margin-bottom: 20px;
    }

    &__link {
        border-bottom: 1px solid #000;
        cursor: pointer;

        &:hover {
            color: #000;
        }
    }

    &__btns {
        display: flex;
        text-align: center;
    }

    &__btn--secondary {
        border: 1px solid;
        margin-right: 14px;
        padding: 4px 15px;
    }

    &__btn--primary {
        color: #fff;
        padding: 4px 15px;
    }

    @media (min-width: 380px) {
        &__wrapper {
            padding: 12px 20px;
        }
    }

    @media (min-width: 600px) {
        &__wrapper {
            flex-direction: row;
            padding: 20px 25px;
        }

        &__text {
            margin: 0 20px 0 0;
        }

        &__btns {
            flex-direction: column-reverse;
        }

        &__btn--secondary {
            margin: 8px 0 0 0;
            width: max-content;
        }
    }

    @media (min-width: 725px) {
        &__wrapper {
            font-size: 16px;
            padding: 25px 30px;
        }
    }

    @media (min-width: 805px) {
        &__wrapper {
        }
    }

    @media (min-width: 860px) {
        &__btns {
            flex-direction: row;
        }

        &__btn--secondary {
            margin: 0 12px 0 0;
        }
    }
}

</style>
